.newHeroMainContainer {
    background-image: url('../../Assets/slide_1.png');
}
.newHeroBgImage {
    top:0vh;
    transition: 500ms ease-in-out;
    animation: bgMove 500ms normal;
}
.newHeroBannerText {
    animation: textControll 500ms normal;
    
}
@keyframes textControll {
    0% {
        opacity: 0
    }
    10% {
        opacity: 0.1;
    }
    20% {
        opacity: 0.2;
    }
    30% {
        opacity: 0.3;
    }
    40% {
        opacity: 0.4;
    }
    50% {
        opacity: 0.5;
    }
    60% {
        opacity: 0.6;
    }
    70% {
        opacity: 0.7;
    }
    80% {
        opacity: 0.8;
    }
    90% {
        opacity: 0.9;
    }
    100% { 
        opacity: 1
    }
}
@keyframes bgMove {
    0% {top: 90vh;}
    15% {top: 80vh;}
  25%  {top: 70vh;}
  40%  {top: 60vh;}
  50%  {top: 50vh;}
  65%  {top: 40vh;}
  75%  {top: 30vh;}
  90%  {top:20vh}
  100% {top: 0vh;}
}